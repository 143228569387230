import { environmentConfig } from '../config/environment/environmentConfig'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import DefaultLayout from '../components/layout/DefaultLayout'
import LoginCard from '../components/app/login/LoginCard'
import type { NextPage } from 'next'

const LoginPage: NextPage = () => {
    const { t } = useTranslation('login')

    return (
        <DefaultLayout title={t('loginPage.title')}>
            <LoginCard />
        </DefaultLayout>
    )
}

export const getServerSideProps: GetServerSideAndEnvironmentProps<{}> = async ({ locale = 'cs' }) => {
    return {
        props: {
            ...(await serverSideTranslations(locale, ['common', 'login'])),
            environmentConfig
        }
    }
}

export default LoginPage