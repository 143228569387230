import { useCurrentUser } from '../auth/auth'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

const useMiddleware = (onlyLogged = true, redirectTo?: string, enabled: boolean = true) => {
    const { push } = useRouter()
    const { isLogged, isReady } = useCurrentUser()
    const [allowed, setAllowed] = useState<boolean>(true)

    useEffect(() => {
        if (!enabled || !isReady) {
            return
        }
        const disallow = (!isLogged && onlyLogged || isLogged && !onlyLogged)
        setAllowed(!disallow)
        if (disallow && redirectTo) {
            push(redirectTo)
        }
    }, [isLogged, onlyLogged, redirectTo, push, isReady, enabled])
    
    return {
        allowed
    }
}

export default useMiddleware