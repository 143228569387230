import { Grid } from '../base'
import config from '../../config/config.json'
import tw, { styled } from 'twin.macro'

export const LayoutMain = styled.main`
  ${tw`h-full w-full`}
`

export const LayoutLoadingContainer = styled.div`
  ${tw`flex justify-center items-center w-full h-screen`}
`

export const DefaultLayoutGrid = styled(Grid)`
  ${tw`flex min-h-screen flex-col justify-center items-center`}
`

/*
 DASHBOARD LAYOUT STYLES
*/

export const DashboardLayoutContainer = styled.div`
  ${tw`mx-auto py-10`}
  max-width: 90rem;
`

/*
 ASIDE LAYOUT STYLES
*/

export const AsideLayoutContainer = styled.div`
  ${tw`grid`}
  grid-template-columns: ${config.theme.asideLayout.sidebarWidth} auto
`

export const AsideLayoutSidebar = styled.aside`
  ${tw`block bg-white relative max-h-screen`}
`

export const AsideLayoutSidebarContainer = styled.div<{ $disableScroll?: boolean }>`
  ${tw`px-3 py-5 h-full overflow-y-auto`}
  ${({ $disableScroll }) => {
        return $disableScroll ? tw`overflow-y-hidden` : tw`overflow-y-auto`
    }}
`

export const AsideLayoutContentContainer = styled.div<{ $disableScroll?: boolean }>`
  ${tw`px-5 py-10 max-h-screen overflow-y-auto`}
  ${({ $disableScroll }) => {
        return $disableScroll ? tw`overflow-y-hidden` : tw`overflow-y-auto`
    }}
`

export const AsideLayoutContent = styled.div`
  ${tw`mx-auto`}
  width: 45.875rem;
`