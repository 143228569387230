import { ScrollTopButtonProps } from './ScrollTopButton.types'
import { ScrollTopButtonRoot } from './ScrollTopButton.styles'
import MaximizeIcon from '../../../assets/img/maximize-icon.svg'
import React, { MouseEvent } from 'react'

const ScrollTopButton = ({ ...props }: ScrollTopButtonProps) => {
    const onClick = (event: MouseEvent<HTMLButtonElement>) => {
        props?.onClick(event)
    }

    return (
        <ScrollTopButtonRoot {...props} onClick={onClick}>
            <MaximizeIcon className='fill-white'/>
        </ScrollTopButtonRoot>
    )
}

export default ScrollTopButton