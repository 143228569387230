import * as yup from 'yup'
import { AxiosError } from 'axios'
import { CenteredFormCard, Checkbox, Column, Grid, Link, Row, TextField } from '../../base'
import { FieldPath } from 'react-hook-form/dist/types/path'
import { LoginRequestData } from '../../../auth/auth'
import { handleFormErrorsFromAxios } from '../../../utils/Helper'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import NextLink from 'next/link'
import React from 'react'
import useSignIn from '../../../hooks/api/auth/useSignIn'
import validator from 'validator'

const LoginCard = () => {
    const router = useRouter()
    const { t } = useTranslation('login')
    const { mutate: signIn, isLoading } = useSignIn()

    const {
        handleSubmit,
        register,
        formState: { errors },
        setError,
        getValues
    } = useForm<LoginRequestData>({
        resolver: yupResolver(
            yup.object({
                email: yup.string()
                    .test('is-email',
                        t('loginPage.form.email.validation.notValid'),
                        value => {
                            return validator.isEmail(value || '')
                        }
                    )
                    .required(t('loginPage.form.email.validation.required')),
                password: yup.string().required(t('loginPage.form.password.validation.required'))
            })
        ),
        reValidateMode: 'onChange'
    })

    const submit = (data: LoginRequestData) => {
        return signIn({ data }, {
            onSuccess: (response) => {
                if (typeof response === 'string') {
                    const email = getValues('email')
                    if (email) {
                        router.replace(`login/verification?email=${email}`)
                        return
                    }
                }
                router.push('dashboard/invoices')
            },
            onError: (error) => {
                return handleFormErrorsFromAxios(error as AxiosError<{ message: string, errors?: object }>, setError)
            }
        })
    }

    const getFieldError = (path: FieldPath<LoginRequestData>) => {
        return errors[path]?.message
    }

    const renderTitle = () => {
        return (
            <h1 tw='text-h1 text-primary-400'>{t('loginPage.title')}</h1>
        )
    }

    const renderHeader = () => {
        return (
            <img src='/logo.svg' alt='Logo'/>
        )
    }

    const renderDescription = () => {
        return (
            <p tw='text-large font-medium text-primary-400'>
                {t('loginPage.registration.description')}&nbsp;
                <NextLink href='/registration' passHref>
                    <Link tw='text-large'>{t('loginPage.registration.link')}</Link>
                </NextLink>
            </p>
        )
    }

    const renderFooter = () => {
        return (
            <NextLink href='/forgotten-password' passHref>
                <Link tw='text-large'>
                    {t('loginPage.forgottenPasswordLink')}
                </Link>
            </NextLink>
        )
    }
    
    return (
        <CenteredFormCard
            onSubmit={handleSubmit(submit)}
            submitLabel={t('loginPage.form.submit')}
            loading={isLoading}
            title={renderTitle()}
            header={renderHeader()}
            footer={renderFooter()}
            description={renderDescription()}
        >
            <Grid>
                <Row>
                    <Column cols={12}>
                        <TextField 
                            label={t('loginPage.form.email.label')}
                            type='email'
                            invalidMessage={getFieldError('email')}
                            {...register('email')}
                        />
                    </Column>
                    <Column cols={12}>
                        <TextField 
                            label={t('loginPage.form.password.label')}
                            type='password'
                            invalidMessage={getFieldError('password')}
                            {...register('password')}
                        />
                    </Column>
                    <Column cols={12}>
                        <Checkbox label={t('loginPage.form.rememberMe')}/>
                    </Column>
                </Row>
            </Grid>
        </CenteredFormCard>
    )
}

export default LoginCard
