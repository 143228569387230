import { Column, Row } from '../base'
import { DefaultLayoutGrid } from './Layout.styles'
import { DefaultLayoutProps } from './Layout.types'
import Layout from './Layout'
import React from 'react'
import useMiddleware from '../../hooks/useMiddleware'

const DefaultLayout = ({ children, redirect, ...props }: DefaultLayoutProps) => {
    useMiddleware(false, '/dashboard/invoices', redirect)
    
    return (
        <Layout {...props}>
            <DefaultLayoutGrid>
                <Row justify='center'>
                    <Column cols={12} md={8} lg={6} xl={5}>
                        {children}
                    </Column>
                </Row>
            </DefaultLayoutGrid>
        </Layout>
    )
}

export default DefaultLayout
