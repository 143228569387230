import tw, { styled } from 'twin.macro'

export const ScrollTopButtonRoot = styled.button`
    ${tw`
        z-50 
        mr-2 
        fixed 
        bottom-6 
        right-4 
        w-14
        h-14 
        rounded-full 
        bg-primary-300 
        hover:bg-primary-200 
        flex 
        justify-center 
        items-center
    `}
`