import { Column, Row } from '../base'
import { LayoutLoadingContainer, LayoutMain } from './Layout.styles'
import { LayoutProps } from './Layout.types'
import { getColorFromAppState } from '../../utils/Helper'
import { useRouter } from 'next/router'
import { useTheme } from 'styled-components'
import Head from 'next/head'
import LoadingSpinIcon from '../../assets/img/loading-spin-icon.svg'
import React, { MouseEvent, useEffect } from 'react'
import ScrollTopButton from '../base/scroll-top-button/ScrollTopButton'
import config from './../../config/config.json'
import useInvoiceUploadContext from '../../hooks/invoice-upload/useInvoiceUploadContext'

const Layout = ({ children, headers, title: titleProp, description, keywords, backgroundColor = 'white', loading }: LayoutProps) => {
    const title = `${titleProp} | ${config.appName}`
    const theme = useTheme()
    const { isFileDialogOpen, setFileDialogOpen } =useInvoiceUploadContext()
    const router = useRouter()

    useEffect(() => {
        document.body.style.setProperty(
            'background-color',
            getColorFromAppState({ theme, $color: backgroundColor })
        )
    }, [backgroundColor, theme])

    const onClickScrollTopButton = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        setFileDialogOpen(true)
    }

    const renderLoading = () => {
        return loading && (
            <LayoutLoadingContainer>
                <LoadingSpinIcon className='animate-spin fill-primary-300 w-24 h-24'/>
            </LayoutLoadingContainer>
        )
    }

    const renderContent = () => {
        if (loading) {
            return renderLoading()
        }
        return children
    }

    const renderScrollTopButton = () => {
        const isOnInvoicesPage = router.pathname === '/dashboard/invoices'
        if (!isFileDialogOpen && !loading && isOnInvoicesPage) {
            return (
                <Row justify='end'>
                    <Column>
                        <ScrollTopButton onClick={onClickScrollTopButton} />
                    </Column>
                </Row>
            )
        }
    }

    return (
        <>
            <Head>
                <meta name='viewport' content='initial-scale=1.0, width=device-width'/>
                <title>{title}</title>
                {description && <meta name='description' content={description}/>}
                {keywords && <meta name='keywords' content={keywords.join(',')}/>}
                <meta property='og:title' content={title}/>
                {description && <meta property='og:description' content={description}/>}
                <meta property='og:type' content='website'/>
                <link rel='shortcut icon' type='image/jpg' href='/favicon.ico'/>
                {headers}
            </Head>
            <LayoutMain>
                {renderContent()}
            </LayoutMain>
            {renderScrollTopButton()}
        </>
    )
}

export default Layout