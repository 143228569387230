import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { LoginRequestData, User } from '../../auth/auth'
import { MakeUserWithAccessToken } from '@dactyl-npm-packages/dactyl-auth-react'

export const loginUser = async (
    axiosInstance: AxiosInstance,
    requestData: LoginRequestData,
    config: AxiosRequestConfig = {}
): Promise<MakeUserWithAccessToken<User> | string> => {
    const { data } = await axiosInstance.post('auth/login', requestData, config)
    if (data?.message) {
        return data.message as string
    }
    return data.data as MakeUserWithAccessToken<User>
}

export const deleteUser = async (
    axiosInstance: AxiosInstance,
    id: string,
    config: AxiosRequestConfig = {}
): Promise<void> => {
    return await axiosInstance.delete(`users/${id}`, config)
}

export const uploadProfilePicture = async (
    axiosInstance: AxiosInstance,
    photo: File,
    config: AxiosRequestConfig = {}
): Promise<void> => {
    const formData = new FormData()
    formData.set('photo', photo)
    return await axiosInstance.post(
        'users/profile-picture',
        formData,
        {
            ...config,
            headers: {
                ...config.headers,
                'Content-Type': 'multipart/form-data'
            }
        }
    )
}

export const getUserProfilePicture = async (
    axiosInstance: AxiosInstance,
    fileUrl: string,
    config: AxiosRequestConfig = {}
): Promise<Blob> => {
    config = {
        ...config,
        responseType: config.responseType || 'blob'
    }
    const { data } = await axiosInstance.get<Blob>(
        fileUrl,
        config
    )
    return data
}