import { AxiosError } from 'axios'
import { LoginRequestData, User, useCurrentUser } from '../../../auth/auth'
import { MakeUserWithAccessToken } from '@dactyl-npm-packages/dactyl-auth-react'
import { loginUser } from '../../../services/user/UserService'
import { useMutation } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const useSignIn = () => {
    const axiosInstance = useAxios()
    const { setUser, setAccessToken } = useCurrentUser()

    return useMutation<MakeUserWithAccessToken<User> | string, AxiosError, { data: LoginRequestData, signal?: AbortSignal }>(
        ['userProfilePicture'],
        async ({ data, signal }) => {
            return await loginUser(
                axiosInstance,
                data,
                { signal }
            )
        }, {
            onSuccess: (response: MakeUserWithAccessToken<User> | string) => {
                if (typeof response !== 'string') {
                    setAccessToken(response.token)
                    setUser(response)
                }
            }
        })
}

export default useSignIn